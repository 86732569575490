<header>
  <a [routerLink]="['/']" (click)="clickRouterLink()" id='logo'>
    <img src="assets/img/logo.svg" alt="">
  </a>
  <div style="flex: 1 1 auto;"></div>
  <div class='burger' [ngClass]="{'state-2': styleService.view === 'nav'}" (click)="styleService.setView('nav')">
    <div class="patty-01"></div>
    <div class="patty-02"></div>
    <div class="patty-03"></div>
  </div>
</header>

<nav *ngIf="styleService.view === 'nav'">
  <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Willkommen
  </a>
  <a [routerLink]="['/walken-und-joggen']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Walken und Joggen
  </a>
  <a [routerLink]="['/walken-und-joggen/effek']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Walken und Joggen / Effekt
  </a>
  <a [routerLink]="['/walken-und-joggen/anleitung']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Walken und Joggen / Anleitung
  </a>
  <a [routerLink]="['/walken-und-joggen/referenzen']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Walken und Joggen / Referenzen
  </a>

  <a [routerLink]="['/kontakt']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">
    Kontakt
  </a>
</nav>

<main *ngIf="styleService.view === 'main'">
  <router-outlet></router-outlet>
</main>

<footer *ngIf="styleService.view === 'main'">
    <div class="footer_title">
      <b>IDOGO gmbH</b> <br>
      lorem ipsum
    </div>
  <div class="footer_contact">
    <b>Kontakt</b> <br>
    Böheimstraße 47/1 &bull; 70199 Stuttgart <br>
    Telefon: <a href="tel:+4971162007251">+49 (0)711 / 62 00 725-1</a> <br>
    Fax: +49 (0)711 / 62 00 725-3
    E-Mail: <a href="mailto:info@idogo.de">info@idogo.de</a>
  </div>
</footer>

<footer *ngIf="styleService.view === 'nav'" class="small">
<div>
  Stand {{ year }} |
  <a [routerLink]="['/datenschutz']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">Datenschutz</a> |
  <a [routerLink]="['/impressum']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="clickRouterLink()">Impressum</a>
</div>
</footer>
