<h1>
  <span>Kontakt</span> <br>
  IDOGO® Kontakte Europaweit
</h1>
<p>
  <b>Vertrieb und Unterricht</b> <br>
  IDOGO® GmbH • Fam. Tjoa<br>
  Böheimstraße 47/1 <br>
  70199 Stuttgart • Deutschland<br>
  Tel. +49 (0)711 / 62 00 725-1 <br>
  Fax: +49 (0)711 / 62 00 725-3<br>
  Mail. info@idogo.de
  <br><br>
  <b>Vertrieb und Unterricht in Frankreich</b>
  Marie Josephe Augoyard
  12,Le Glapigneux <br>
  38580 Allevard • France
  Tel. +33 47 64 50 312 <br>
  Web. www.idogo-france.com
  <br><br>
  <b>Vertrieb und Unterricht in der Schweiz</b>
  Martin Armbruster <br>
  Baderstr. 4 <br>
  8400 WIntherthur • Switzerland<br>
  Tel. +41 793073787 <br>
  Web. www.idogo.ch
  <br><br>
  <b>Vertrieb und Unterricht in Norwegen</b><br>
  LA LINJA & Dario Augusto Passera<br>
  Postboks 1414 GARD <br>
  5501 Haugesund • Norway
  Tel. 90577033 <br>
  Web. www.lalinja.no
  <br><br>
  <b>Vertrieb und Unterricht in Belgien</b><br>
  Bruno Delanoit<br>
  11 rue du Tilleul <br>
  1315 Incourt • Belgium <br>
  Tel. 0495 / 59 45 53 <br>
  Web. www.idogo.be
</p>
