import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  constructor(
    public api_service: ApiService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.api_service.get_page(this.router.snapshot.data.page_id)
  }
}
