<picture>
  <source media="(min-width: 38em)" srcset="assets/img/start_001.jpg">
  <source srcset="assets/img/start_001-mobile.jpg">
  <img src="assets/img/start_001-mobile.jpg" alt="">
</picture>
<div class="content">
  <h1>
    Willkommen bei IDOGO®
  </h1>
  <p>
    IDOGO® ist ein vollständig neues Trainingssystem zur Leistungssteigerung bei Bewegungsübungen aller Art. Im Gegensatz zum herkömmlichen Konditionstraining beruht die erzielte größere
    Leistungsfähigkeit nicht darauf, dass der Körper an immer höhere Leistungsgrenzen gewöhnt wird, sondern darauf, dass der Körper aufgrund einer in sich ökonomischen Bewegungs- und Atemweise
    auftretende Belastungen leichter verkraften kann.
    <br><br>
    Der Mittelpunk  des neuen IDOGO® - Trainingssystems ist ein spezieller Übungsstab, durch den vorrangig die Atmung optimiert wird. Der entscheidende Vorteil und das absolut neuartige an IDOGO®
    ist, dass die Übenden schon nach kurzer Zeit die gesteigerte Leistungsfähigkeit bei sich selber spüren können. Das Prinzip des IDOGO®-Systems ist eine Energiegewinnung durch optimierte
    Atemvorgänge, die sich aufgrund einer selbsttragenden und entspannenden Körperhaltung und Bewegungsweise einstellen.
    <br><br>
    Das IDOGO®–System hat unter anderem deshalb eine positive Auswirkung auf die Leistungsfähigkeit, weil die Bewegungskoordination im Brust und Schulterbereich und damit auch die Tätigkeit der
    Atemmuskulatur optimiert wird. Durch die koordinierte Armbewegung werden die Blutgefäße erweitert und die arbeitenden Muskeln mit mehr Sauerstoff versorgt. Dadurch ermüdet der Übende nicht
    vorzeitig und kann sein Training somit effektiver und konzentrierter durchführen.
  </p>
</div>
