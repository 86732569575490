import { Injectable } from '@angular/core';
import {GetPageQuery} from "../queries/get-page.query";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public page: any;

  constructor(
    private get_page_query: GetPageQuery
  ) { }

  get_page(page_id: string): void {
    this.page = null;
    this.get_page_query.execute(page_id).subscribe((result) => {
      console.log(result.data.page)
      this.page = result.data.page;
      // this.seo_service.update_title(this.bike.title);
      // this.seo_service.update_keywords(this.bike.metaKeywords);
      // this.seo_service.update_description(this.bike.metaDescription);
      // this.seo_service.create_canonical_url('https://fahrrad-hoss.de/' + bikeUrl);
    });
  }

}
